<template>

</template>

<script>
var that
import Vue from "vue";
import http from '@/utils/httpRequest'
import {twoDomainLogin} from '@/utils/common'
import { Toast } from 'vant';
export default {
  data(){
    return{}
  },
  created(){
    that = this
    let token = that.$route.query.token
    Vue.prototype.$globalData.token = token
    let topToken = that.$route.query.topToken
    Vue.prototype.$globalData.topToken = topToken
    let orgId = that.$route.query.orgId
    Vue.prototype.$orgId = orgId
    Vue.prototype.$globalData.userInfo.orgId = orgId
    let userId = that.$route.query.userId
    Vue.prototype.$globalData.userInfo.userId = userId
    let street = that.$route.query.street
    Vue.prototype.$globalData.userInfo.street = street
    let streetName = that.$route.query.streetName
    Vue.prototype.$globalData.userInfo.streetName = streetName
    let county = that.$route.query.county
    Vue.prototype.$globalData.userInfo.county = county
    let communityName = that.$route.query.communityName
    Vue.prototype.$globalData.userInfo.communityName = communityName
    let userName = that.$route.query.userName
    Vue.prototype.$globalData.userInfo.userName = userName
    let mobile = that.$route.query.mobile
    Vue.prototype.$globalData.userInfo.mobile = mobile
    let address = that.$route.query.address
    Vue.prototype.$globalData.address = address
    Vue.prototype.$globalData.searchValue = that.$route.query.searchValue
    let domain = that.$route.query.domain
    if (domain) {
      console.log(domain);
      Vue.prototype.$globalData.domain = decodeURIComponent(domain) + '/api'
      Vue.prototype.$globalData.domain2 = decodeURIComponent(domain)
      Vue.prototype.$http.changeUrl(domain,false)
    }

    if (that.$route.query.result) {
      Vue.prototype.$globalData.result = that.$route.query.result
    }
    let appid = that.$route.query.appid
    Vue.prototype.$globalData.appid = appid
    Vue.prototype.$url = window.location.href.split('sso')[0]
    console.log(this.$url)
    this.$router.push(that.$route.query.url)
    // let url = window.location.href.split('sso')[0] + that.$route.query.url
    if (that.$route.query.url == 'appSSO') {
      let url = decodeURIComponent(that.$route.query.appUrl)
      let appId = that.$route.query.appId
      let appCode = that.$route.query.appCode
      let appName = that.$route.query.appName
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, peopleType: 0}})
    }
    //监听微信小程序的返回按钮
    window.addEventListener('popstate', (event) => {
      //当不存在历史页面时，退出webview
      if (!event.state) {
        wx.miniProgram.navigateBack();
      }
    });
  },
  methods: {
  }
}

</script>

<style scoped>

</style>

